import { analytics } from '@helpers/analytics'
import { getUserDataFromAnalytics } from '@helpers/session/user'
import { encode } from '@helpers/shopify'
import { getCookieByName } from '@helpers/session'
import {
  LEG,
  CUT,
  SHORTSLEG,
  SIZE,
  LENGTH,
  PRODUCT_PAGE_BREAKDOWN_MERGE_TYPES,
  SWIMSHORTSLEG
} from '@consts/index'
import { removeQueryParamsFromUrl } from '@helpers/utils'
import { getCurrencyCode } from '@helpers/env'
import { CustomAttribute } from 'types/checkout'
import { getCustomerSavedFitFromCookies } from '@helpers/customer/fit'

export function getProductSkuProductId({ sku }: { sku: string | undefined }) {
  return sku?.trim().slice(0, 14)
}

export function getProductSkuGroupId({ sku }: { sku: string | undefined }) {
  return sku?.trim().slice(0, 6)
}

export function getProductFamily(productCategory: string) {
  if (
    productCategory.includes('Tops') ||
    productCategory.includes('The Jack')
  ) {
    return 'Tops'
  }

  if (
    productCategory.includes('Gift') ||
    productCategory.includes('Accessories')
  ) {
    return 'Accessories'
  }

  return 'Bottoms'
}

// TODO: Remove this function when we have a better and unified way to determine product category
export const getProductCategory = (
  options: { type: string }[],
  productType?: string | undefined
): string | null => {
  let category = null
  if (!!options) {
    options.forEach(option => {
      switch (option.type.toLowerCase()) {
        case LEG.toLowerCase():
        case 'lower leg':
        case 'taper':
          category = 'Trousers'
          break

        case SHORTSLEG.toLowerCase():
        case SWIMSHORTSLEG.toLowerCase():
          category = 'Shorts'
          break

        case CUT.toLowerCase():
        case SIZE.toLowerCase():
        case LENGTH.toLowerCase():
          category = 'Tops'
          break

        default:
          category = 'Accessories'
      }
    })
  }

  if (!!productType) {
    if (productType.includes('Trunks')) {
      category = 'Underwear'
    } else if (productType.includes('Denim') && category !== 'Tops') {
      category = 'Denim'
    } else if (productType.includes('Gift')) {
      category = 'Gift Card'
    } else if (productType.includes('Swims')) {
      category = 'Shorts'
    } else if (productType.includes('Socks') || productType.includes('Belts')) {
      category = 'Accessories'
    } else if (productType.includes('The Jack')) {
      category = 'The Jack'
    }
  }

  return category
}

export function mergeProductType(
  productType: string
): keyof typeof PRODUCT_PAGE_BREAKDOWN_MERGE_TYPES | string {
  const pType = Object.keys(PRODUCT_PAGE_BREAKDOWN_MERGE_TYPES).map(key => {
    const mergeType =
      PRODUCT_PAGE_BREAKDOWN_MERGE_TYPES[
        key as keyof typeof PRODUCT_PAGE_BREAKDOWN_MERGE_TYPES
      ]
    if (mergeType?.includes(productType)) {
      return key
    }
  })
  return pType[0] || productType
}

export function selectedFitEvent(
  optionType: string,
  currentOptionValue: string,
  extraProps?: { fulfillment?: string } & Record<string, string>
) {
  const pageType = /(collections)/i.test(window.location.href)
    ? 'Collection'
    : 'Product'
  const props = {
    category: `${pageType} Page`,
    label: `Product option (${optionType} - ${currentOptionValue})`,
    url: window.location.href,
    ...extraProps
  }
  // Add a custom property to product filters event ONLY
  if (pageType === 'Product') props.fulfillment = 'Normal'
  analytics('Click', props)
}

type CommonEventProduct = {
  url?: string
  product_feed?: {
    brand?: string
    category?: string
    product_id?: string
    group_id?: string
  }
  title?: string
  product_type?: string
  sku?: string
  price?: string
}

type CommonEventProductProperties = {
  brand: string
  category?: string
  color?: string
  currency: string
  name?: string
  product_id: string | undefined
  price: number | null
  group_id: string | undefined
  url: string
}

export const facebookPixelId = getCookieByName('_fbp') || ''
export const facebookAdClickId = getCookieByName('_fbc') || ''

export function commonEventProductProperties(
  product: CommonEventProduct
): CommonEventProductProperties {
  const formmattedUrl = removeQueryParamsFromUrl(
    product.url || window.location.href
  )
  return {
    brand: product.product_feed?.brand || 'SPOKE',
    category: product.product_feed?.category,
    color: product.title,
    currency: getCurrencyCode(),
    name: product.product_type,
    product_id:
      product.product_feed?.product_id ||
      getProductSkuProductId({ sku: product.sku }),
    price: Number(product.price) || null,
    group_id:
      product.product_feed?.group_id ||
      getProductSkuGroupId({ sku: product.sku }),
    url: formmattedUrl
  }
}

export function getProductVariantAsString(
  properties: Record<string, string | number>
): string | null {
  if (
    properties === null ||
    properties === undefined ||
    Object.keys(properties).length === 0
  ) {
    return null
  }

  return Object.keys(properties)
    .map(key => properties[key])
    .filter(v => v)
    .join(' / ')
}

export function productEventUserProperties() {
  const { traits } = getUserDataFromAnalytics()
  return traits
}

export function productViewedEvent(
  product: CommonEventProduct & { image: { src: string } },
  options?: Record<string, unknown>
) {
  const savedFit = getCustomerSavedFitFromCookies()
  const commonProps = commonEventProductProperties(product)
  const { email, firstName, phone } = productEventUserProperties()
  const props = {
    ...commonProps,
    ...options,
    image_url: product.image.src,
    ...(!!facebookPixelId && { fbp: facebookPixelId }),
    ...(!!facebookAdClickId && { fbc: facebookAdClickId }),
    saved_fit: !!savedFit,
    ...{ email, firstName, phone }
  }

  analytics('Product Viewed', props)
}

export function productSkuViewedEvent(
  product: CommonEventProduct & { image: { src: string } },
  options?: Record<string, unknown>
) {
  const savedFit = getCustomerSavedFitFromCookies()
  const commonProps = commonEventProductProperties(product)
  const props = {
    ...commonProps,
    ...options,
    image_url: product.image.src,
    ...(!!facebookPixelId && { fbp: facebookPixelId }),
    ...(!!facebookAdClickId && { fbc: facebookAdClickId }),
    ...productEventUserProperties(),
    saved_fit: !!savedFit
  }
  analytics('Product SKU Viewed', props)
}

export type ProductListViewedArgs = {
  products: {
    quantity: number
    name: string
    color: string
    price: number
    position: number
    url: string
    image_url: string
    brand: string
  }[]
  context: string
  listId?: string
  listName?: string
  [key: string]: unknown
}
export function productListViewed({
  products,
  context,
  listId,
  listName,
  ...rest
}: ProductListViewedArgs) {
  const savedFit = getCustomerSavedFitFromCookies()
  analytics(
    'Product List Viewed',
    {
      context,
      products: products.slice(0, 10),
      ...(listId && { list_id: listId }),
      ...(listName && { list_name: listName }),
      saved_fit: !!savedFit,
      ...rest
    },
    {
      integrations: {
        All: true,
        'Facebook Pixel': false
      }
    }
  )
}

export function checkFiltersProductCategory(
  productOptions: Partial<{ type: string }>[]
): 'tops' | 'bottoms' {
  const isTopsCategory =
    !!productOptions &&
    (productOptions.find(attr => attr.type === 'cut') || {}).type
  return !!isTopsCategory ? 'tops' : 'bottoms'
}

export const getCareCopyProductTypeLabel = (productType: string) => {
  if (productType.toLowerCase().includes('merino')) {
    return 'merino'
  }

  if (productType.toLowerCase().includes('denim')) {
    return 'denim'
  }

  if (productType.toLowerCase().includes('polo')) {
    return 'polo'
  }

  if (productType.toLowerCase().includes('socks')) {
    return 'socks'
  }

  return productType.toLowerCase()
}

export function getProductTagValue(tagName: string, tag = '') {
  return tag?.replace(`${tagName}:`, '').trim()
}

const tagsMap = {
  beta: 'Beta',
  new: 'New',
  preorder: 'Pre-order',
  'new season': 'New Season',
  'low stock': 'Low Stock',
  organic: 'Organic',
  'out-of-stock': 'Out of stock'
} as const

function formatTag(tag: string): string {
  const tagFormatted = tag.toLowerCase().trim()

  switch (true) {
    case tagFormatted.includes('auto-discount'):
      return `${getProductTagValue('auto-discount', tag)} off`

    case tagFormatted.includes('betawear'):
      return 'Beta'

    case tagFormatted.includes('organic'):
      return 'Organic'

    case tag.includes('new product'):
      return 'New'

    case tag.includes('fabric'):
      return tagsMap[getProductTagValue('fabric', tag) as keyof typeof tagsMap]

    case tag.includes('limited-edition'):
      return 'Limited Edition'

    default:
      return tagsMap[tagFormatted as keyof typeof tagsMap]
  }
}

type Tags = string[]
type ValidTags = readonly string[] | undefined

function getFilteredTags(tags: Tags) {
  const cleanedTags = tags.map(tag => tag.toLowerCase().trim())

  const tagsWithAutomaticDiscount = cleanedTags.filter(tag =>
    tag.includes('auto-discount')
  )
  if (tagsWithAutomaticDiscount.length > 0) {
    const tagsWithoutAutomaticDiscount = cleanedTags.filter(
      tag => !tag.includes('auto-discount')
    )

    const tagsSortedByLowerDiscountAmount = tagsWithAutomaticDiscount
      ? tagsWithAutomaticDiscount.sort()
      : []

    const automaticDiscountTag = tagsSortedByLowerDiscountAmount.find(
      tag => tag
    )

    if (automaticDiscountTag) {
      return [automaticDiscountTag, ...tagsWithoutAutomaticDiscount]
    }
  }

  return cleanedTags
}

function findProductTag(tags: Tags, validTags: ValidTags) {
  const filterTags = getFilteredTags(tags)
  const tag = filterTags.find(tag => {
    const findMatchingValidTag = validTags?.find(validTag =>
      tag.includes(validTag)
    )
    return findMatchingValidTag
  })

  return tag
}

export function getProductTag(
  tags: Tags | undefined = [],
  validTags: ValidTags = []
): string | null {
  if (!tags) return null

  const productTag = findProductTag(tags, validTags)

  return productTag ? formatTag(productTag) : null
}

export function combineVariantsInventoryLevel(
  variants: { inventory_quantity: number }[] = []
) {
  if (!variants) return 0

  return variants.reduce(
    (total, variant) => total + variant.inventory_quantity,
    0
  )
}

type ProductCartItemsArgs = {
  customAttributes: CustomAttribute[]
  quantity?: number
  variantId: string
}

export function getProductCartItems({
  quantity = 1,
  customAttributes,
  variantId
}: ProductCartItemsArgs): {
  quantity: number
  merchandiseId: string
  attributes: CustomAttribute[]
}[] {
  return [
    {
      quantity,
      merchandiseId: variantId,
      attributes: [
        ...customAttributes,
        { key: '_addedToCartTimestamp', value: `${Date.now()}` }
      ]
    }
  ]
}
