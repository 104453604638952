/* eslint-disable prettier/prettier */
import Translations from '@helpers/translations'

export const SIZES_TEXT = {
  buildText: {
    A: Translations.localise('common.sizes.build-a-desc'),
    B: Translations.localise('common.sizes.build-b-desc'),
    C: Translations.localise('common.sizes.build-c-desc')
  },
  taperText: {
    Skinny:
      'Our slimmest taper below the knee for a tighter fit around the calves.',
    Slim: Translations.localise('common.sizes.taper-slim-desc'),
    Standard: Translations.localise('common.sizes.taper-standard-desc'),
    Straight: Translations.localise('common.sizes.taper-straight-desc')
  }
} as const

export const SIZES = {
  waist: [
    '28',
    '30',
    '31',
    '32',
    '33',
    '34',
    '35',
    '36',
    '37',
    '38',
    '40',
    '42',
    '44',
    '46'
  ],
  build: ['Build A', 'Build B', 'Build C'],
  pack: ['Single', '2 Pack', '3 Pack'],
  taper: ['Slim', 'Standard', 'Straight'],
  leg: ['28', '29', '30', '31', '32', '33', '34', '35', '36', '37'],
  size: ['S', 'M', 'M+', 'L', 'L+', 'XL', 'XXL'],
  'chest size': ['36', '38', '40', '42', '44', '46', '48'],
  SizeVerbose: ['Small', 'Medium', 'Medium+', 'Large', 'Large+', 'XL', 'XXL'],
  cut: ['Slim', 'Straight'],
  length: ['Short', 'Regular', 'Long'],
  shortsleg: ['3', '5', '7', '9', '11'],
  sizeSocks: ['S/M', 'M/L']
} as const

export const FINISHED_TO_ORDER = 'Finished to Order'
export const WAIST = 'Waist'
export const BUILD = 'Build'
export const LEG = 'Leg'
export const SIZE = 'Size'
export const CHEST_SIZE = 'Chest Size'
export const CUT = 'Cut'
export const LENGTH = 'Length'
export const SHORTSLEG = 'ShortsLeg'
export const TAPER = 'Taper'
export const SWIMSHORTSLEG = 'swimshortsleg'
export const SHOW_COLLECTION_PRODUCT_TYPE = ['blue order', 'taper']
export const PRODUCTION_PATH = 'https://spoke-london.com'
export const STAGING_PATH = 'https://spoke-staging.com'
export const TROUSERS = 'trousers'
export const TOPS = 'tops'
export const SHORTS = 'shorts'
export const LOWER_LEG_SLIM_SHORT = 'Slim Short'
export const LOWER_LEG_SLIM_LONG = 'Slim Long'
export const LOWER_LEG_SLIM_SHORT_SKU = '03'
export const LOWER_LEG_SLIM_LONG_SKU = '04'
export const SLIM_TAPER_REGEX = new RegExp(
  String.raw`${LOWER_LEG_SLIM_LONG}|${LOWER_LEG_SLIM_SHORT}`,
  'g'
)

export const ADDITIONAL_PROPERTIES = {
  taper: {
    helperButton: true,
    skipInCookies: true,
    type: 'taper'
  },
  build: {
    helperButton: false,
    type: 'build',
    skipInCookies: false
  },
  ignoreSizeOptionType: {
    helperButton: false,
    skipInCookies: true
  }
} as const

export const NO_SHOW_ATTRIBUTES_PROPS = [
  '_add-on',
  '_introducing',
  '_addedToCartTimestamp'
] as const

export const GIFT_CARD = {
  hero: {
    mobile: '//cdn.shopify.com/s/files/1/0207/9406/files/GiftPageMobile.jpg',
    desktop: '//cdn.shopify.com/s/files/1/0207/9406/files/GiftPageDesktop.jpg',
    tablet: '//cdn.shopify.com/s/files/1/0207/9406/files/GiftPageTablet.jpg'
  },
  sidebar: [
    'https://cdn.shopify.com/s/files/1/0207/9406/files/Spoke_GC_Not_Socks.png?v=1588240932',
    'https://cdn.shopify.com/s/files/1/0207/9406/files/Leg_Day_-_Contents_-_1000px_ce179df2-c616-405c-9e6d-4d761c94b710.png?v=1587988319',
    'https://cdn.shopify.com/s/files/1/0207/9406/files/Fit_Happens_-_Contents_-_1000px_da87eb77-cb08-4ee2-8a92-6436e6ea4fcc.png?v=1587988344',
    'https://cdn.shopify.com/s/files/1/0207/9406/files/Spoke_GC_No_Cover_9ebd08df-2ca9-4071-89af-d3e41edcb3d9.jpg?v=1588240962'
  ],
  prices: ['£25.00', '£50.00', '£100.00', '£150.00', '£200.00'],
  values: ['25', '50', '100', '150', '200'],
  radios: [
    // {
    //   step: 'A',
    //   value: 'Not socks',
    //   id: 'not-socks'
    // },
    {
      step: 'A',
      value: "Fit's about to get real",
      id: 'fit-about-to-get-real'
    },
    {
      step: 'B',
      value: "Friends don't let friends...",
      id: 'friend-dont-let-friends'
    },
    {
      step: 'C',
      value: 'I got this gift card because...',
      id: 'got-this-gift-because'
    },
    {
      step: 'D',
      value: 'No outer box',
      id: 'no-outer-box'
    }
  ]
} as const

export const ORDERED_OPTIONS = [
  WAIST,
  BUILD,
  LEG,
  SHORTSLEG,
  SIZE,
  CHEST_SIZE,
  CUT,
  LENGTH,
  TAPER
] as const

export const KLAVIYO_SMS_LIST_IDS = {
  GB: {
    prod: 'UmYUMe',
    dev: 'SeqFpb'
  },
  US: {
    prod: 'XD4fGP',
    dev: 'RCbVbM'
  },
  EU: {
    prod: '',
    dev: ''
  }
} as const

export const KLAVIYO_EMAIL_LIST_IDS = {
  prod: {
    first_tier_marketing: 'PAVDLA'
  },
  dev: {
    first_tier_marketing: 'QptFXT'
  }
} as const

export const LOCALES = {
  GB: 'en-GB',
  US: 'en-US',
  EU: 'en-EU'
} as const

export const CURRENCY_CODES = {
  GB: 'GBP',
  US: 'USD',
  EU: 'EUR'
} as const

export const STORE_ANALYTICS_CODES = {
  uk: 'uk',
  gb: 'uk',
  us: 'us',
  eu: 'eu'
} as const

export const STORES_PHONE_NUMBERS = {
  phone: {
    gb: '0203 735 7814',
    us: '+1 855-658-7760',
    eu: '+44 203 735 7814'
  },
  whatsApp: '442037357814'
} as const

export const STORES_SMS_NUMBERS = {
  phone: {
    gb: '+44 (0) 203 735 7814',
    us: '+1 855-658-7760',
    eu: '+44 (0) 203 735 7814'
  },
  whatsApp: '+44 (0) 203 735 7814'
} as const

export const STORES_HELPDESK_LINKS = {
  gb: 'https://help.spoke-london.com/en',
  us: 'https://help.spoke-london.com/en',
  eu: 'https://help.spoke-london.com/en'
} as const

export const STORE_HELPDESK_OPEN_TIMES = {
  gb: '9-5pm',
  us: '9-5pm',
  eu: '10-6pm'
} as const

export const STATIC_PAGES = [
  { title: 'Colors', handle: '/colors' },
  { title: 'Been referred by a friend?', handle: '/thank-your-friend' },
  { title: 'Contact Us', handle: '/contact-us' },
  { title: 'Fit Finder', handle: '/fit-finder' },
  { title: 'Returns', handle: '/returns' },
  {
    title: 'Tell a friend, earn rewards',
    handle: '/refer-a-friend'
  },
  { title: 'Spoke Feedback', handle: '/spoke-feedback' },
  { title: 'Size Chart (tops)', handle: '/tops-size-chart' },
  { title: 'Size Chart (trousers)', handle: '/trousers-size-chart' },
  { title: 'Size Chart (shorts)', handle: '/shorts-size-chart' },
  { title: 'Size Chart (denim)', handle: '/denim-size-chart' },
  { title: 'Size Chart (underwear)', handle: '/underwear-size-chart' },
  { title: 'Size Chart (accessories)', handle: '/accessories-size-chart' },
  { title: 'Unsubscribe', handle: '/unsubscribe' },
  { title: 'Enduros', handle: '/enduros-range' },
  { title: 'Search', handle: '/search' }
] as const

export const HEADERS = {
  'x-api-key': process.env.API_KEY
} as const

export const CONTENTFUL_HEADERS_ID = {
  productBreakdownCollection: '2ZOavGHUoBP3AoYrUmORht',
  productBreakdownGiftingCollection: '2xd2p6pt3TpFz6u2uCkuqR',
  productBreakdownProducts: '5RvZs1gCUsbDWUpxvgRA9P'
} as const

export const HIDE_FLASH_BANNER = ['/heroes'] as const

export const PRODUCT_PAGE_BREAKDOWN_MERGE_TYPES = {
  Lightweights: [
    'Cotton Lightweights',
    'Linen Lightweights',
    'Summer Sharps',
    'Linen Sharps'
  ],
  Denim: ['Japanese Denim', '12oz Denim'],
  Fives: ['Cord Fives'],
  Sharps: ['Cord Sharps'],
  Heroes: ['Winter Heroes']
} as const

export const MEDIA_QUERY_BREAKPOINTS = {
  SMALL_MOBILE: 'SMALL_MOBILE',
  TABLET: 'TABLET',
  PHABLET: 'PHABLET',
  TABLET_LARGE: 'TABLET_LARGE',
  // TABLET_PRO: "TABLET_PRO",
  DESKTOP: 'DESKTOP',
  DESKTOP_LARGE: 'DESKTOP_LARGE',
  DESKTOP_EXTRA_LARGE: 'DESKTOP_EXTRA_LARGE',
  TV: 'TV'
} as const

export const BREAKPOINT_SIZES = {
  [MEDIA_QUERY_BREAKPOINTS.SMALL_MOBILE]: 350,
  [MEDIA_QUERY_BREAKPOINTS.TABLET]: 600,
  [MEDIA_QUERY_BREAKPOINTS.PHABLET]: 700,
  [MEDIA_QUERY_BREAKPOINTS.TABLET_LARGE]: 768,
  // [MEDIA_QUERY_BREAKPOINTS.TABLET_PRO]: 1024,
  [MEDIA_QUERY_BREAKPOINTS.DESKTOP]: 1000,
  [MEDIA_QUERY_BREAKPOINTS.DESKTOP_LARGE]: 1281,
  [MEDIA_QUERY_BREAKPOINTS.DESKTOP_EXTRA_LARGE]: 1600
} as const

export const PRODUCT_COLOUR_TAGS = [
  'auto-discount',
  'low stock',
  'fabric: new season'
] as const

export const PRODUCT_TYPE_TAGS = [
  'betawear',
  'new product',
  'preorder',
  'organic'
] as const

export const PRODUCT_TYPES_WITHOUT_COLORS = ['Gift Card'] as const

export const SHIPPING_FEES = {
  threshold: {
    GB: {
      cost: '£100',
      value: 100
    },
    US: {
      cost: '$150',
      value: 150
    },
    EU: {
      cost: '150€',
      value: 150
    }
  }
} as const

export const COOKIE_EXPIRE_14_DAYS = 14
export const COOKIE_EXPIRE_1_MONTH = 30
export const COOKIE_EXPIRE_3_MONTHS = 90
export const COOKIE_EXPIRE_6_MONTHS = 180
export const COOKIE_EXPIRE_1_YEAR = 365

export const DYNAMIC_FILTER_OPTIONS_BY_OPTION_TYPE = [
  'waist',
  'build',
  'leg',
  'taper',
  'shortsleg',
  'swimshortsleg',
  'chest size',
  'cut'
] as const
export const DYNAMIC_FILTER_OPTIONS_BY_PRODUCT_TYPE = [
  'stripe socks',
  'house socks'
] as const

export const OPEN_FIT_FINDER = 'fit_finder_open'

export const FLASH_BANNER_HEIGHT = 35

export const ROYAL_MAIL_PARCEL_COLLECT_CARRIER_ID = 330